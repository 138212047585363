import { createI18n } from 'vue-i18n';
import {
  app,
  pages,
  inputs,
  buttons,
  components,
} from '@/settings/i18n/locales/br.json';

const messages = {
  en: {
    app,
    pages,
    inputs,
    buttons,
    components,
  },
  br: {
    app,
    pages,
    inputs,
    buttons,
    components,
  },
};

const i18n = createI18n({
  messages,
  locale: 'br',
  globalInjection: true,
  fallbackLocale: 'en',
});

export default i18n;
