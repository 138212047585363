<script setup lang="ts">
  import { type HTMLAttributes, computed } from 'vue';
  import { Separator, type SeparatorProps } from 'radix-vue';
  import { cn } from '@/lib/utils';

  const props = defineProps<
    SeparatorProps & { class?: HTMLAttributes['class'] }
  >();

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
  });
</script>

<template>
  <Separator
    v-bind="delegatedProps"
    :class="
      cn(
        'shrink-0 bg-zinc-200 dark:bg-zinc-800',
        props.orientation === 'vertical' ? 'h-full w-px' : 'h-px w-full',
        props.class,
      )
    " />
</template>
