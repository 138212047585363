<template>
  <div v-if="pagination.totalPages > 1" class="flex justify-end">
    <div
      v-show="pagination.page > 1"
      class="transform cursor-pointer px-2 transition duration-500 ease-in-out hover:translate-y-1 hover:scale-105"
      @click="backPage()">
      <i class="fa-solid fa-circle-arrow-left h-8 w-8"></i>
    </div>
    <div
      v-show="pagination.page < pagination.totalPages"
      class="transform cursor-pointer transition duration-500 ease-in-out hover:translate-y-1 hover:scale-105"
      @click="nextPage()">
      <i class="fa-solid fa-circle-arrow-right h-8 w-8"></i>
    </div>
  </div>
</template>
<script lang="ts">
  import type { ListPaginationInterface } from '@/interfaces/base/pagination/listPagination.interface';
  import type { PaginationInterface } from '@/interfaces/base/pagination/pagination.interface';
  import { defineComponent, type PropType } from 'vue';

  export default defineComponent({
    name: 'Pagination',
    props: {
      pagination: {
        type: Object as PropType<ListPaginationInterface>,
        required: true,
      },
    },
    emits: ['paramsPagination'],
    data() {
      return {
        paramsPagination: {
          page: 1,
          pageSize: 20,
        } as PaginationInterface,
      };
    },
    mounted() {
      this.paramsPagination.page = this.pagination.page;
    },
    methods: {
      nextPage() {
        this.paramsPagination.page = Number(this.pagination.page) + 1;
        this.$emit('paramsPagination', this.paramsPagination);
      },
      backPage() {
        this.paramsPagination.page = Number(this.pagination.page) - 1;
        this.$emit('paramsPagination', this.paramsPagination);
      },
    },
  });
</script>
