import type { RouteRecordRaw } from 'vue-router';

/**
 * Routes for the account module
 */
const accountRoutes: RouteRecordRaw[] = [
  {
    name: 'ProfileGroup',
    path: 'profile',
    component: () => import('@/views/private/account/profile/index.vue'),
    children: [
      {
        name: 'Company',
        path: 'company',
        component: () =>
          import('@/views/private/account/profile/mySubsidiary.vue'),
      },
      {
        name: 'BankAccount',
        path: 'bankAccount',
        component: () =>
          import('@/views/private/account/profile/bankDetails.vue'),
      },
      {
        name: 'Profile',
        path: 'me',
        component: () =>
          import('@/views/private/account/profile/myProfile.vue'),
      },
      {
        name: 'Billing',
        path: 'billing',
        component: () =>
          import('@/views/private/account/profile/subscriptionPage.vue'),
      },
      {
        name: 'Preferences',
        path: 'identity',
        component: () =>
          import('@/views/private/account/profile/subsidiaryPreferences.vue'),
      },
    ],
  },
  {
    name: 'Dashboard',
    path: 'dashboard',
    component: () => import('@/views/private/account/dashboard.vue'),
  },
  {
    name: 'Users',
    path: 'users',
    component: () => import('@/views/private/account/manageUsers.vue'),
  },
];

const pipelineRoutes: Array<RouteRecordRaw> = [
  {
    path: ':pipelineName',
    props: true,
    name: 'Deals',
    component: () => import('@/views/private/pipeline/index.vue'),
  },
];

/**
 * Routes for the deal module
 */
const dealRoutes: RouteRecordRaw[] = [
  {
    name: 'Deal',
    path: ':dealId',
    props: true,
    component: () => import('@/views/private/deal/DealById.vue'),
  },
  {
    props: true,
    name: 'UpdateDeal',
    path: ':dealId/edit/:step?',
    component: () => import('@/views/private/deal/CreateDeal.vue'),
  },
  {
    name: 'CreateDeal',
    path: 'new',
    props: true,
    component: () => import('@/views/private/deal/CreateDeal.vue'),
  },
];

const simulationRoutes: RouteRecordRaw[] = [
  {
    name: 'SimpleRealEstateSimulation',
    path: 'realty/simple',
    component: () => import('@/views/private/simulations/realty/simple.vue'),
  },
  {
    name: 'DeadlinesRealEstateSimulation',
    path: 'realty/deadlines',
    component: () => import('@/views/private/simulations/realty/deadlines.vue'),
  },
  {
    name: 'BanksRealEstateSimulation',
    path: 'realty/banks',
    component: () => import('@/views/private/simulations/realty/banks.vue'),
  },
  {
    name: 'DetailedRealEstateSimulation',
    path: 'realty/detailed',
    component: () => import('@/views/private/simulations/realty/detailed.vue'),
  },
  {
    name: 'BestMatchRealEstateSimulation',
    path: 'realty/bestMatch',
    component: () => import('@/views/private/simulations/realty/bestMatch.vue'),
  },
];

// Private routes
export const privateRoutes: RouteRecordRaw[] = [
  {
    name: 'Pipeline',
    path: '/meus-negocios',
    children: pipelineRoutes,
    component: () => import('@/views/private/baseLayout.vue'),
  },
  {
    name: 'Base',
    path: '/',
    meta: { requiresAuth: true },
    redirect: '/account/dashboard',
    component: () => import('@/views/private/baseLayout.vue'),
    children: [
      {
        name: 'Users',
        path: 'users',
        component: () => import('@/views/private/account/manageUsers.vue'),
      },
      {
        name: 'Products',
        path: 'products',
        component: () => import('@/views/private/products/ListProducts.vue'),
      },
      {
        name: 'BankPartners',
        path: 'bankpartners',
        component: () =>
          import('@/views/private/bankPartner/bankPartnerMainPage.vue'),
      },
      {
        name: 'Subsidiary',
        path: 'subsidiary',
        component: () => import('@/views/private/subsidiary/index.vue'),
      },
      {
        name: 'Simulations',
        path: 'simulations',
        component: () => import('@/views/private/simulations/list/index.vue'),
      },
      {
        name: 'Commissions',
        path: 'commissions',
        component: () => import('@/views/private/commissions/index.vue'),
      },
      {
        name: 'Simulation',
        path: 'simulation',
        component: () =>
          import('@/views/private/simulations/simulationsMainPage.vue'),
        children: simulationRoutes,
      },

      {
        name: 'VehicleSimulation',
        path: 'simulation/vehicle',
        component: () =>
          import('@/views/private/simulations/vehicle/index.vue'),
      },
      {
        name: 'Courses',
        path: 'courses',
        component: () => import('@/views/private/courses/listCourses.vue'),
      },
      {
        name: 'Customers',
        path: 'customers',
        component: () => import('@/views/private/customer/listCustomer.vue'),
      },
      {
        name: 'Account',
        path: 'account',
        children: accountRoutes,
      },
      {
        name: 'DealGroup',
        path: 'deal',
        children: dealRoutes,
      },
    ],
  },
  {
    name: 'Payment',
    path: '/payment',
    meta: { requiresAuth: true },
    component: () => import('@/views/private/account/payment.vue'),
  },
  {
    name: 'ParameterizedPayment',
    path: '/payment/:plan',
    meta: { requiresAuth: true },
    component: () => import('@/views/private/account/payment.vue'),
  },
];

// Public routes
export const publicRoutes: RouteRecordRaw[] = [
  {
    name: 'Login',
    path: '/login',
    component: () => import('@/views/public/loginScreen.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'FAQ',
    path: '/faq',
    component: () => import('@/views/public/faqScreen.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'NewPassword',
    path: '/change-password/:token',
    props: true,
    component: () => import('@/views/public/createNewPassword.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'RecoverPassword',
    path: '/recover-password',
    component: () => import('@/views/public/recoverPassword.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'PrivacyPolicy',
    path: '/politica-privacidade',
    component: () => import('@/views/public/privacyPolicy.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'TermsOfUse',
    path: '/termo-de-uso',
    component: () => import('@/views/public/termsOfUse.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'CookiePolicy',
    path: '/cookies',
    component: () => import('@/views/public/cookiesPolicy.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'Subscribe',
    path: '/cadastro',
    component: () => import('@/views/public/subscribeScreen.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'PreRegistration',
    path: '/pre-cadastro',
    component: () => import('@/views/public/preRegistration.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'preRegistrationParameterized',
    path: '/pre-cadastro/:name',
    component: () => import('@/views/public/preRegistration.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'PreRegistrationCompleted',
    path: '/pre-cadastro-completo',
    component: () => import('@/views/public/preRegistrationCompleted.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'PublicDealForm',
    path: '/cadastro-cliente/:assetSegment/:operationType/:dealTokenId',
    props: true,
    component: () => import('@/views/public/deal/publicDealForm.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'PublicSubsidiary',
    path: '/cadastro-filial/:companyId/:subsidiaryId',
    props: true,
    component: () =>
      import('@/views/public/subsidiary/subsidiaryRegistration.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/public/404.vue'),
    meta: { requiresAuth: false },
  },
];
