import { privateRoutes, publicRoutes } from '@/router/routes';
import authMiddleware from '@/router/middlewares/auth';
// import localStorageMiddleware from '@/router/middlewares/localStorage';

import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from 'vue-router';
const routes: Array<RouteRecordRaw> = [...privateRoutes, ...publicRoutes];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

authMiddleware(router);
// localStorageMiddleware(router);

export default router;
