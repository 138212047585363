import type { PermissionInterface } from '@/interfaces/base/accessControl/permission.interface';
/**
 *
 * @param { PermissionInterface[] } permissions Array com todas permissões do user
 * @param { string } domain Módulo que deseja validar a permissão
 * @param { string } action Permissão que deseja validar
 * @returns { boolean } Boolean indicando se o usuário tem permissão ou não
 */
export function useCheckPermission(
  permissions: Array<PermissionInterface>,
  domain: PermissionInterface['module'],
  action: PermissionInterface['action'],
): boolean {
  return permissions.some(
    (permission) =>
      permission.module === domain && permission.action === action,
  );
}
