import type { Router } from 'vue-router';
import { useUserStore } from '@/stores/user.store';

const authMiddleware = (router: Router) => {
  router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore();

    // Ignorar navegação para a mesma rota
    if (from.path === to.path) return next();

    // Continuar se a rota não requer autenticação
    if (!to.meta.requiresAuth) return next();

    // Verificar se o usuário está autenticado
    const accessData = userStore.auth;
    if (!accessData || accessData.accessToken === '') {
      return next('/login');
    }

    if (
      userStore.remainingTime.hours <= 0 &&
      userStore.remainingTime.minutes <= 0 &&
      userStore.remainingTime.seconds <= 0
    ) {
      userStore.logout(router);
      return next('/login');
    }

    next();
  });
};

export default authMiddleware;
