import type { GetCompanySimplifiedInterface } from '@/interfaces/company/getCompanySimplified.interface';
import type { SubsidiaryInterface } from '@/interfaces/subsidiary';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useSubsidiaryStore = defineStore(
  'AtenaSubsidiaryStore',
  () => {
    const subsidiary = ref<SubsidiaryInterface | null>(null);
    const simplifiedCompanyResponse = ref<GetCompanySimplifiedInterface[]>([]);

    const companies = computed(() => simplifiedCompanyResponse.value);

    const setCompanies = (payload: GetCompanySimplifiedInterface[]) => {
      simplifiedCompanyResponse.value = payload;
    };

    const clear = () => {
      subsidiary.value = null;
    };

    const setInformation = async (payload: SubsidiaryInterface) => {
      subsidiary.value = payload;
    };

    return { companies, subsidiary, setCompanies, clear, setInformation };
  },
  {
    persist: {
      storage: localStorage,
    },
  },
);
