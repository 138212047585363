<template>
  <div class="flex w-full flex-wrap items-center py-8">
    <div v-if="!isDark" class="ml-auto mr-auto">
      <img :src="$options.imageConfig.system.noItemDark" class="h-auto w-56" />
    </div>
    <div v-else class="ml-auto mr-auto">
      <img :src="$options.imageConfig.system.noItem" class="h-auto w-56" />
    </div>
    <div
      v-if="text !== undefined"
      class="w-full text-center text-xl font-semibold text-lightSystemRegularFont dark:text-darkSystemRegularFont">
      {{ text }}
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue';
  import { useDark } from '@vueuse/core';
  export default defineComponent({
    name: 'EmptyList',
    props: {
      text: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isDark: useDark(),
      };
    },
    methods: {},
  });
</script>
