import FormData from 'form-data';
import type { Axios } from 'axios';
import type { ChangeCompany } from '@/interfaces/company/changeCompany.interface';
import type { ImageInterface } from '@/interfaces/base/image/index.interface';
import type { LoginDTO } from '@/interfaces/user/dto/login.dto';
import type { LoginResponseInterface } from '@/interfaces/user/responses/loginResponseInterface';
import type { MeResponseInterface } from '@/interfaces/user/meResponse.interface';
import type { PaginatedResponse } from '@/interfaces/base/pagination/paginatedResponse.interface';
import type { RequestParametersInterface } from '@/interfaces/user/requestParameters.interface';
import type { ResponseApiInterface } from '@/interfaces/base/responseApi.interface';
import type { UpdateUserRequestInterface } from '@/interfaces/user/updateUserRequest.interface';
import type { UserRegistrationInterface } from '@/interfaces/user/userRegistration.interface';
import { UseHttp } from '@/composables/useHttp';
import { useErrorMessage } from '@/composables/useErrorMessage';

export class UserServices {
  private http = new UseHttp(import.meta.env.VITE_APP_URL_API, true);
  private service: Axios;
  private response: ResponseApiInterface;

  constructor() {
    this.service = this.http.getInstance;
    this.response = {
      data: null,
    };
  }

  /**
   * Realiza o login do usuário
   * @param {string} login.email
   * @param {string} login.password
   * @returns
   */
  async login(login: LoginDTO) {
    try {
      const { data } = await this.service.post<LoginResponseInterface>(
        '/api/v1/auth/login',
        {
          email: login.email.toLowerCase().trim(),
          password: login.password,
        },
        {
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
        },
      );
      this.response = { data };
      return this.response as ResponseApiInterface<typeof data>;
    } catch (error) {
      console.error(error);
      this.response = useErrorMessage(error, 'Erro ao realizar login');
      return this.response;
    }
  }

  /**
   * Realiza a chamada à API para atualizar os dados de autenticação
   * @param {string} refreshToken token fornecido no login
   * @returns
   */
  async refreshToken(refreshToken: string) {
    try {
      const { data } = await this.service.post<{
        expiresIn: number;
        authorization: string;
        refreshToken: string;
      }>('/api/v1/auth/refreshToken', {
        refreshToken,
      });
      this.response = { data };
      return this.response as ResponseApiInterface<typeof data>;
    } catch (error) {
      this.response = useErrorMessage(
        error,
        'Erro ao atualizar dados de autenticação',
      );
      return this.response;
    }
  }

  /**
   * Realiza a chamada à API para obter os dados do usuário
   */
  async getUserInformation() {
    try {
      const { data } = await this.service.get<MeResponseInterface>(
        '/api/v1/user/me/logged',
      );
      this.response = { data };
      return this.response as ResponseApiInterface<typeof data>;
    } catch (error) {
      this.response = useErrorMessage(
        error,
        'Erro ao recuperar informações do usuário',
      );
      return this.response;
    }
  }

  /**
   * Realiza a chamada à API para aceitar os termos de serviço e política de privacidade
   * @param payload
   * @returns
   */
  async acceptPolicies(payload: {
    acceptTermsOfUse: boolean;
    acceptPrivacyPolicy: boolean;
  }) {
    try {
      const { data } = await this.service.post<string>(
        '/api/v1/auth/acceptPolicies',
        payload,
      );
      this.response = { data };
      return this.response as ResponseApiInterface<typeof data>;
    } catch (error) {
      this.response = useErrorMessage(
        error,
        'Falha ao atualizar termos de uso e política de privacidade',
      );
      return this.response;
    }
  }

  /**
   * Realiza a chamada à API para receber o email de recuperação de senha.
   * @param email
   * @returns
   */
  async recoverPassword(email: string) {
    try {
      const { data } = await this.service.post<{
        title: string;
        message: string;
      }>('/api/v1/auth/forgotPassword', {
        email,
      });
      this.response = { data };
      return this.response as ResponseApiInterface<typeof data>;
    } catch (error) {
      this.response = useErrorMessage(
        error,
        'Erro enviar email de recuperação de senha',
      );
      return this.response;
    }
  }

  /**
   * Realiza a chamada à API para redefinir a senha de usuário
   * @param {sting} token token recebido por email
   * @param {string} password senha digitada no input
   * @param {string} passwordConfirmation confirmação de senha do input
   * @returns
   */
  async newPassword(
    token: string,
    password: string,
    passwordConfirmation: string,
  ) {
    try {
      const { data } = await this.service.post<string>(
        '/api/v1/auth/changePassword',
        {
          token,
          password,
          passwordConfirmation,
        },
      );
      this.response = { data };
      return this.response as ResponseApiInterface<typeof data>;
    } catch (error) {
      this.response = useErrorMessage(error, 'Erro ao redefinir a senha');
      return this.response;
    }
  }

  /**
   * Realiza a chamada à API que retorna uma lista de usuários do sistema
   * @param params Filtros
   * @returns Lista de usuários paginada
   */
  async getUsersWithParameters(params?: Partial<RequestParametersInterface>) {
    try {
      const { data } = await this.service.get<
        PaginatedResponse<{
          id: string;
          email: string;
          name: string;
          role: string; //TODO: verificar possibilidade de enum
        }>
      >('/api/v1/user', {
        params,
      });
      this.response = { data };
      return this.response as ResponseApiInterface<typeof data>;
    } catch (error) {
      this.response = useErrorMessage(
        error,
        'Erro ao obter informações do usuário',
      );
      return this.response;
    }
  }

  /**
   * Filtro de busca que irá procurar pelo:
   *
   * - Nome da pessoa associada ao usuário.
   *
   * - Documento da pessoa associada ao usuário.
   *
   * - Contatos da pessoa associada ao usuário.
   * @param search
   */
  async getUsersWithAutoComplete(search?: string) {
    try {
      const { data } = await this.service.get<
        Array<{
          id: string;
          title: string;
        }>
      >('/api/v1/user/autocomplete', {
        params: {
          search,
        },
      });
      this.response = { data };
      return this.response as ResponseApiInterface<typeof data>;
    } catch (error) {
      this.response = useErrorMessage(
        error,
        'Erro ao obter informações do usuário',
      );
      return this.response;
    }
  }

  /**
   * Realiza a chamada à API que adiciona um novo usuário ao sistema
   * @param user Dados do usuário
   * @returns
   */
  async createUser(user: UserRegistrationInterface) {
    try {
      const { data } = await this.service.post<{
        id: string;
        email: string;
        roleId: string;
      }>('/api/v1/user', user);
      this.response = { data };
      return this.response as ResponseApiInterface<typeof data>;
    } catch (error) {
      this.response = useErrorMessage(
        error,
        'Erro ao adicionar o usuário ao sistema',
      );
      return this.response;
    }
  }

  /**
   * Remove um usuário do sistema
   * @param id userID
   * @returns 204
   */
  async removeUser(id: string) {
    try {
      const { data } = await this.service.delete<string>(`/api/v1/user/${id}`);
      this.response = { data };
      return this.response as ResponseApiInterface<typeof data>;
    } catch (error) {
      this.response = useErrorMessage(error, 'Erro ao excluir usuário');
      return this.response;
    }
  }

  /**
   * Atualiza as informações de perfil do usuário
   * @param payload
   * @returns 204
   */
  async updateUser(payload: UpdateUserRequestInterface) {
    try {
      const { data } = await this.service.patch<string>(
        '/api/v1/user/profile',
        payload,
      );
      this.response = { data };
      return this.response as ResponseApiInterface<typeof data>;
    } catch (error) {
      this.response = useErrorMessage(
        error,
        'Erro ao atualizar informações do usuário',
      );
      return this.response;
    }
  }

  /**
   * Realiza a chamada à API para atualizar a foto de perfil usuário
   * @param payload
   * @returns
   */
  async updateProfilePicture(payload: FormData) {
    try {
      const { data } = await this.service.post<NonNullable<ImageInterface>>(
        '/api/v1/user/upload',
        payload,
      );
      this.response = { data };
      return this.response as ResponseApiInterface<typeof data>;
    } catch (error) {
      this.response = useErrorMessage(
        error,
        'Erro ao atualizar foto do usuário',
      );
      return this.response;
    }
  }

  /**
   * Realiza a chamada à API para trocar os dados da empresa
   * @param payload
   * @returns
   */
  async changeCompany(payload: ChangeCompany) {
    try {
      const { data } = await this.service.patch(
        '/api/v1/user/change-company',
        payload,
      );
      this.response = { data };
      return this.response as ResponseApiInterface<typeof data>;
    } catch (error) {
      this.response = useErrorMessage(error, 'Erro ao trocar de empresa');
      return this.response;
    }
  }

  /**
   * Altera a Afiliado atual do usuário
   * @param {string} token bearer token
   * @param {string} subsidiaryId id do Afiliado alvo
   * @returns status code 200
   */
  async changeSubsidiary(subsidiaryId: string) {
    try {
      const { status } = await this.service.patch<string>(
        '/api/v1/user/subsidiary/change',
        {
          subsidiary: {
            id: subsidiaryId,
          },
        },
      );

      this.response = {
        data: status,
      };
      return this.response as ResponseApiInterface<typeof status>;
    } catch (error) {
      this.response = useErrorMessage(error, 'Erro ao trocar de filial');
      return this.response;
    }
  }
}
