<template>
  <Line :id="id" :data="chartData" :options="chartOptions" />
</template>
<script setup lang="ts">
  import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
  } from 'chart.js';
  import type { ChartData, ChartOptions } from 'chart.js';
  import { Line } from 'vue-chartjs';
  import { ref } from 'vue';

  ChartJS.register(
    CategoryScale,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
  );

  const props = defineProps({
    data: {
      type: Object as () => ChartData<'line'>,
      required: true,
    },
    options: {
      type: Object as () => ChartOptions<'line'>,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  });

  const chartData = ref<ChartData<'line'>>(props.data);

  const chartOptions = ref<ChartOptions<'line'>>(props.options);

  // const data = ref({
  //   labels: props.labels,
  //   datasets: props.datasets,
  // });

  // const gridColor = ref(
  //   darkTheme.value ? `rgba(255, 255, 255, 1)` : `rgba(0, 0, 0, 1)`,
  // );

  // const options = ref<ChartOptions<'line'>>({
  //   responsive: true,
  //   layout: {
  //     padding: 5,
  //   },
  //   scales: {
  //     //horizontal
  //     y: {
  //       grid: {
  //         color: gridColor.value,
  //         lineWidth: 0.2,
  //       },
  //       border: {
  //         dash: [7, 7],
  //       },
  //     },
  //     //vertical
  //     x: {
  //       grid: {
  //         display: false,
  //       },
  //     },
  //   },
  //   datasets: {
  //     line: {
  //       borderColor:
  //         data.value.datasets.length > 1 ? undefined : `rgba(5, 36, 221, 0.4)`,
  //       hoverBackgroundColor:
  //         data.value.datasets.length > 1 ? undefined : `rgba(5, 36, 221, 1)`,
  //       tension: 0.2,
  //     },
  //   },
  //   plugins: {
  //     tooltip: {
  //       backgroundColor: `rgba(5, 36, 200, 1)`,
  //       xAlign: 'center',
  //       yAlign: 'bottom',
  //       titleAlign: 'center',
  //       displayColors: false,
  //       callbacks: {
  //         label: function (context) {
  //           let label = '';
  //           if (context.parsed.y !== null) {
  //             label += new Intl.NumberFormat('pt-BR', {
  //               style: 'currency',
  //               currency: 'BRL',
  //             }).format(context.parsed.y);
  //           }
  //           return label;
  //         },
  //       },
  //     },
  //   },
  // });
</script>
