<template>
  <div class="uploadBase">
    <div
      :id="dropzoneComponentData.id ? dropzoneComponentData.id : ``"
      :class="[
        hasError.active || displayError
          ? `!border-red-500`
          : `border-lightSystemInactiveInputBorder dark:border-darkSystemInactiveInputBorder`,
      ]"
      class="uploadZone group">
      <DropZone
        ref="dropzone"
        method="POST"
        :acceptedFiles="dropzoneComponentData.acceptedTypes"
        :dropzoneClassName="
          dropzoneComponentData.hasFile ? `hasFile` : `noFile`
        "
        :dropzoneMessageClassName="`hidden`"
        :maxFileSize="Number(dropzoneComponentData.maxFileSize)"
        :maxFiles="Number(dropzoneComponentData.maxFiles)"
        :multipleUpload="dropzoneComponentData.multipleUpload"
        :uploadOnDrop="dropzoneComponentData.uploadOnDrop"
        :url="dropzoneComponentData.uploadUrl"
        @uploaded="onUploaded"
        @added-file="onAdd"
        @error-add="onError"
        @sending="onSend"
        @removed-file="onRemoved">
      </DropZone>
      <div
        :class="[dropzoneComponentData.hasFile ? `hidden` : `dropFileMessage`]">
        <div class="icon items-center">
          <i class="fa-solid fa-arrow-up-from-bracket h-full w-full"></i>
        </div>
        <p class="">
          <b>Clique aqui</b>
          <br />para enviar ou arraste um arquivo PNG, SVG ou GIF <br />(máx.
          2mb.)
        </p>
      </div>
    </div>
    <div class="messages">
      <p v-if="hasError.active || displayError" class="message error">
        {{ hasError.errorMessage || props.displayErrorMessage }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { NotificationInterface } from '@/interfaces/components/notification/notification.interface';
  import { ref } from 'vue';

  const emit = defineEmits(['showNotification', 'update:modelValue']);

  const props = defineProps({
    id: {
      type: String,
      required: false,
      default: '',
    },
    maxFiles: {
      type: Number,
      required: true,
      default: 1,
    },
    uploadUrl: {
      type: String,
      required: false,
      default: '',
    },
    maxFileSize: {
      type: Number,
      required: false,
      default: 2000000, //2mb
    },
    acceptedTypes: {
      type: Array<string>,
      required: true,
      default: ['png', 'jpg'],
    },
    uploadOnDrop: {
      type: Boolean,
      required: true,
      default: false,
    },
    multipleUpload: {
      type: Boolean,
      required: true,
      default: false,
    },
    displayError: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayErrorMessage: {
      type: String,
      required: false,
      default: '',
    },
  });

  const dropzone = ref();
  const hasError = ref({
    active: props.displayError,
    errorMessage: props.displayErrorMessage,
    errorType: '',
  });

  const dropzoneComponentData = ref({
    id: props.id,
    maxFiles: props.maxFiles,
    uploadUrl: props.uploadUrl || import.meta.env.VITE_FILE_UPLOAD,
    maxFileSize: props.maxFileSize,
    acceptedTypes: props.acceptedTypes,
    uploadOnDrop: props.uploadOnDrop,
    multipleUpload: props.multipleUpload,
    hasFile: false,
    completedUpload: false,
  });

  function onUploaded() {
    const filesId = dropzone.value.ids as string[];

    if (dropzoneComponentData.value.maxFileSize === 1) {
      dropzone.value.all[0][filesId[0]].status === 'DONE'
        ? (dropzoneComponentData.value.completedUpload = true)
        : (dropzoneComponentData.value.completedUpload = false);
    }

    if (dropzoneComponentData.value.maxFileSize > 1) {
      let uploadedFiles = [];
      for (let item of filesId) {
        uploadedFiles.push(dropzone.value.all[item].status);
      }
      uploadedFiles.includes('ERROR')
        ? (dropzoneComponentData.value.completedUpload = false)
        : (dropzoneComponentData.value.completedUpload = true);
    }
  }

  function onSend(fileDetails: File[], request: XMLHttpRequest) {
    request.onreadystatechange = (e: Event) => {
      const xhr = e.currentTarget as XMLHttpRequest;
      const { response } = xhr;
      if (response == null || response == false || response === undefined) {
        return false;
      } else {
        props.maxFiles === 1
          ? emit('update:modelValue', fileDetails[0])
          : emit('update:modelValue', fileDetails);
      }
    };
  }

  function onAdd(fileDetails: { file: File; id: string }) {
    dropzoneComponentData.value.hasFile = true;
    hasError.value.errorMessage = '';
    hasError.value.active = false;

    emit('update:modelValue', fileDetails.file);
  }

  function onRemoved() {
    dropzoneComponentData.value.hasFile = false;
    dropzoneComponentData.value.completedUpload = false;
    emit('update:modelValue', dropzoneComponentData.value.completedUpload);
  }

  function onError(ev?: Event) {
    const result = ev as unknown as { error: string; files: File[] };
    if (result) {
      hasError.value.active = true;
      hasError.value.errorType = result.error;

      switch (result.error) {
        case 'MAX_FILE_SIZE':
          hasError.value.errorMessage = `Tamanho máximo permitido 2mb (${(
            result.files[0].size / 1000000
          ).toFixed(1)}mb)`;
          emit('showNotification', {
            type: 'warning',
            title: 'Arquivo muito grande ',
            message: hasError.value.errorMessage,
            showMessage: true,
          } as NotificationInterface);
          break;

        case 'INVALID_TYPE':
          hasError.value.errorMessage = 'Falha ao selecionar arquivo!';
          emit('showNotification', {
            type: 'warning',
            title: 'A extensão do arquivo não é permitida',
            message: hasError.value.errorMessage,
            showMessage: true,
          } as NotificationInterface);
          break;

        case 'MAX_FILE':
          dropzoneComponentData.value.maxFiles > 1
            ? (hasError.value.errorMessage =
                'Você pode selecionar somente 1 arquivo')
            : (hasError.value.errorMessage = `Você pode inserir até ${dropzoneComponentData.value.maxFiles} arquivos`);

          emit('showNotification', {
            type: 'warning',
            title: 'Limite de arquivos excedido!',
            message: hasError.value.errorMessage,
            showMessage: true,
          } as NotificationInterface);
          break;

        default:
          hasError.value.errorMessage = `${result.error} - Favor consultar o suporte.`;
          emit('showNotification', {
            type: 'error',
            title: 'Erro desconhecido',
            message: hasError.value.errorMessage,
            showMessage: true,
          } as NotificationInterface);
          break;
      }
    }
  }
</script>
