<template>
  <div
    :class="[notificationData.showMessage ? 'show' : 'hide']"
    class="fixed absolute left-0 top-0 z-40 flex h-screen max-h-screen w-screen items-center justify-center overflow-hidden bg-zinc-600/80 opacity-100 backdrop-blur-sm dark:bg-black/80">
    <div
      :class="notificationData.type"
      class="relative w-1/2 max-w-lg rounded-2xl bg-white px-6 pb-8 pt-8 shadow-xl ring-2 dark:bg-zinc-900 sm:mx-auto sm:w-auto sm:max-w-sm sm:px-10 md:w-auto">
      <div class="flex justify-between">
        <div :class="notificationData.type" class="flex w-full items-center">
          <div v-if="notificationData.type" class="icon mt-1 !h-9 !w-9">
            <i
              :class="`${statusIcons[notificationData.type]} h-full w-full`"></i>
          </div>

          <h2 class="title overflow-hidden text-ellipsis px-4">
            {{ notificationData.title }}
          </h2>
        </div>
        <button
          v-if="showCloseButton"
          class="close !h-9 !w-9"
          @click="$emit('close')">
          <i class="fa-regular fa-circle-xmark h-full w-full"></i>
        </button>
      </div>
      <div class="overflow-hidden text-ellipsis py-4 text-xl">
        <p>
          {{ notificationData.message }}
        </p>
      </div>

      <div class="slot">
        <slot name="buttons"></slot>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue';
  import type { NotificationInterface } from '@/interfaces/components/notification/notification.interface';

  export default defineComponent({
    name: 'NotificationBase',
    props: {
      notification: {
        type: Object as () => NotificationInterface,
        required: false,
        default: () => ({
          message: 'Texto da mensagem',
          showMessage: false,
          type: 'error',
          title: 'Título da Mensagem',
          id: new Date().toString(),
        }),
      },
      showCloseButton: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    emits: ['close'],
    data(props) {
      return {
        notificationData: props.notification || ({} as NotificationInterface),
        statusIcons: {
          success: 'fa-solid fa-circle-check text-green-600',
          warning: 'fa-solid fa-circle-exclamation text-yellow-600',
          error: 'fa-solid fa-circle-xmark text-red-600',
        },
      };
    },
    watch: {
      notification() {
        this.notificationData = this.notification;
      },
    },
  });
</script>
