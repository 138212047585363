<script setup lang="ts">
  import { type HTMLAttributes, computed } from 'vue';
  import {
    SelectIcon,
    SelectTrigger,
    type SelectTriggerProps,
    useForwardProps,
  } from 'radix-vue';
  import { ChevronDown } from 'lucide-vue-next';
  import { cn } from '@/lib/utils';

  const props = defineProps<
    SelectTriggerProps & { class?: HTMLAttributes['class'] }
  >();

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
  });

  const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <SelectTrigger
    v-bind="forwardedProps"
    :class="
      cn(
        'flex h-10 w-full items-center justify-between rounded-md border border-zinc-200 bg-white px-3 py-2 text-sm ring-offset-white placeholder:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-zinc-950 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-zinc-800 dark:bg-zinc-950 dark:ring-offset-zinc-950 dark:placeholder:text-zinc-400 dark:focus:ring-zinc-300 [&>span]:line-clamp-1',
        props.class,
      )
    ">
    <slot />
    <SelectIcon asChild>
      <ChevronDown class="h-4 w-4 opacity-50" />
    </SelectIcon>
  </SelectTrigger>
</template>
