<template>
  <div>
    <div
      v-if="showPreferences"
      class="overlay fixed left-0 top-0 flex h-screen w-screen">
      <div
        class="relative m-auto h-1/2 w-1/2 rounded-2xl bg-white p-4 sm:w-5/6">
        <button
          class="absolute -right-5 -top-5 sm:-top-8 sm:right-0"
          @click="showPreferences = false">
          <i class="fa-regular fa-circle-xmark h-4 w-4 text-white"></i>
        </button>
        <div class="flex h-full flex-col gap-4 overflow-auto p-4 text-zinc-800">
          <div class="flex flex-col gap-2">
            <h1>{{ $t('components.cookieBanner.popup.title') }}</h1>
            <p>{{ $t('components.cookieBanner.popup.description') }}</p>
            <RouterLink to="/cookies" class="underline">{{
              $t('components.cookieBanner.popup.moreInformation')
            }}</RouterLink>
          </div>
          <div class="flex flex-row gap-4 sm:flex-col">
            <button
              class="rounded-xl bg-zinc-700 px-3 py-2 text-white"
              @click="declineAllCookies">
              {{ $t('components.cookieBanner.buttons.denyAll') }}
            </button>
            <button
              class="rounded-xl bg-darkSystemHighlight px-3 py-2 text-black"
              @click="acceptAllCookies">
              {{ $t('components.cookieBanner.buttons.allowAll') }}
            </button>
          </div>
          <div class="flex flex-col divide-y-2 pt-4">
            <div
              v-for="(item, index) in cookiesTypes"
              :key="index"
              class="flex flex-row items-center gap-2 py-4">
              <div class="w-20">
                <label
                  class="relative ml-2 inline-flex cursor-pointer items-center">
                  <input
                    v-model="item.isActive"
                    type="checkbox"
                    class="peer sr-only"
                    :disabled="item.disabled"
                    @click="item.isActive = !item.isActive" />
                  <div
                    class="peer h-6 w-11 rounded-full border border-zinc-600 bg-primary after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border-zinc-600 after:bg-zinc-600 after:transition-all after:content-[''] peer-checked:border-primary peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-white peer-focus:outline-none peer-focus:ring-0 dark:bg-transparent dark:peer-focus:ring-primary"></div>
                </label>
              </div>
              <div class="flex w-full flex-col gap-2">
                <span class="font-bold">{{ item.title }}</span>
                <span>{{ item.text }}</span>
              </div>
            </div>
            <div class="flex w-full pt-4">
              <button
                class="ml-auto w-fit rounded-xl bg-darkSystemHighlight px-3 py-2 text-black"
                @click="setCookies">
                {{ $t('components.cookieBanner.buttons.save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="fixed bottom-0 flex w-full bg-black text-white">
      <div
        class="mx-auto flex flex-row items-center gap-4 py-4 text-sm sm:flex-col sm:px-5 md:px-5">
        <div class="sm:text-center">
          {{ $t('components.cookieBanner.banner.description') }}
          <RouterLink to="/cookies" class="underline">{{
            $t('components.cookieBanner.popup.moreInformation')
          }}</RouterLink>
        </div>
        <div class="flex flex-row gap-4">
          <button
            class="rounded bg-zinc-700 px-2 py-1 text-white sm:px-3 sm:py-2"
            @click="handlePreferences">
            <p>{{ $t('components.cookieBanner.buttons.configure') }}</p>
          </button>
          <button
            class="rounded bg-darkSystemHighlight px-2 py-1 text-black sm:px-3 sm:py-2"
            @click="acceptAllCookies">
            <p>{{ $t('components.cookieBanner.buttons.allowAll') }}</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'CookieBanner',
    emits: ['showCookieBanner'],
    data() {
      return {
        showPreferences: false,
        cookiesTypes: {
          essential: {
            isActive: true,
            disabled: true,
            title: this.$t(
              'components.cookieBanner.popup.options.essential.title',
            ),
            text: this.$t(
              'components.cookieBanner.popup.options.essential.description',
            ),
          },
          performance: {
            isActive: true,
            disabled: false,
            title: this.$t(
              'components.cookieBanner.popup.options.performance.title',
            ),
            text: this.$t(
              'components.cookieBanner.popup.options.performance.description',
            ),
          },
          analytic: {
            isActive: true,
            disabled: false,
            title: this.$t(
              'components.cookieBanner.popup.options.analytic.title',
            ),
            text: this.$t(
              'components.cookieBanner.popup.options.analytic.description',
            ),
          },
          ads: {
            isActive: true,
            disabled: false,
            title: this.$t('components.cookieBanner.popup.options.ads.title'),
            text: this.$t(
              'components.cookieBanner.popup.options.ads.description',
            ),
          },
          social: {
            isActive: true,
            disabled: false,
            title: this.$t(
              'components.cookieBanner.popup.options.social.title',
            ),
            text: this.$t(
              'components.cookieBanner.popup.options.social.description',
            ),
          },
        },
      };
    },
    mounted() {
      const showCookiePreferencesBanner = this.getCookie(
        'bextPro_showCookieBanner',
      );
      if (!showCookiePreferencesBanner) {
        this.setCookie('bextPro_showCookieBanner', 'true', 30);
      }
      this.getCookiesPreferences();
    },
    methods: {
      setCookies() {
        this.setCookie('bextPro_showCookieBanner', 'false', 30);

        this.setCookie(
          'bextPro_essential',
          String(this.cookiesTypes.essential.isActive),
          30,
        );
        this.setCookie(
          'bextPro_ads',
          String(this.cookiesTypes.ads.isActive),
          30,
        );
        this.setCookie(
          'bextPro_analytic',
          String(this.cookiesTypes.analytic.isActive),
          30,
        );
        this.setCookie(
          'bextPro_performance',
          String(this.cookiesTypes.performance.isActive),
          30,
        );
        this.setCookie(
          'bextPro_social',
          String(this.cookiesTypes.social.isActive),
          30,
        );

        this.$emit('showCookieBanner', false);
      },
      declineAllCookies() {
        for (const index in this.cookiesTypes) {
          if (index === 'essential') {
            this.cookiesTypes[index].isActive = true;
          } else {
            this.cookiesTypes[
              index as keyof typeof this.cookiesTypes
            ].isActive = false;
          }
        }
        this.setCookies();
      },
      acceptAllCookies() {
        for (const index in this.cookiesTypes) {
          this.cookiesTypes[index as keyof typeof this.cookiesTypes].isActive =
            true;
        }
        this.setCookies();
      },
      handlePreferences() {
        this.showPreferences = true;
      },
      getCookie(name: string) {
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
          const [cookieName, cookieValue] = cookie.split('=');
          if (cookieName === name) {
            return decodeURIComponent(cookieValue);
          }
        }
        return null;
      },
      setCookie(name: string, value: string, days: number) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${encodeURIComponent(value)}; ${expires}; path=/; SameSite=Strict; Secure`;
      },
      getCookiesPreferences() {
        const essentialCookie = this.getCookie('bextPro_essential');
        const adsCookie = this.getCookie('bextPro_ads');
        const analyticCookie = this.getCookie('bextPro_analytic');
        const performanceCookie = this.getCookie('bextPro_performance');
        const socialCookie = this.getCookie('bextPro_social');

        this.cookiesTypes.essential.isActive = essentialCookie === 'true';
        this.cookiesTypes.ads.isActive = adsCookie === 'true';
        this.cookiesTypes.analytic.isActive = analyticCookie === 'true';
        this.cookiesTypes.performance.isActive = performanceCookie === 'true';
        this.cookiesTypes.social.isActive = socialCookie === 'true';
      },
    },
  });
</script>
