<template>
  <div>
    <div :class="customClass" class="glassCard">
      <PageTitle v-show="showTitle" :pageTitle="title" />
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue';
  export default defineComponent({
    name: 'MainPageWrapper',
    props: {
      showTitle: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      customClass: {
        type: String,
        required: false,
        default: '',
      },
    },
  });
</script>
