<template>
  <div class="relative" :class="[openItens ? 'z-50' : 'z-0']">
    <label
      v-if="label"
      class="text-lightSystemRegularFont dark:text-darkSystemRegularFont">
      {{ label }}
    </label>

    <div
      class="relative flex w-full items-center bg-white px-1 dark:bg-darkSystemGeneral"
      :class="openItens ? 'rounded-t-2xl' : 'rounded-2xl'">
      <input
        id="inputSearch"
        v-model="inputValue"
        :placeholder="placeholder"
        class="w-11/12 border-0 bg-transparent !ring-0"
        type="text"
        @keyup="filter" />

      <div v-if="!openItens" class="w-1/12" @click="openItens = true">
        <i class="fa-solid fa-chevron-down"></i>
      </div>
      <div v-if="openItens" class="w-1/12" @click="openItens = false">
        <i class="fa-solid fa-angle-up"></i>
      </div>
      <div
        :class="[{ hidden: !isLoading }]"
        class="absolute inset-y-0 right-0 w-fit rounded-2xl bg-inherit">
        <IsLoading class="h-full w-12" />
      </div>
    </div>

    <div class="absolute inset-x-0">
      <div
        v-if="openItens"
        class="relative z-20 max-h-80 w-full overflow-y-auto rounded-b-xl bg-white p-2 dark:bg-darkSystemGeneral">
        <ul v-for="option in filteredItems" :key="option.value">
          <li
            class="w-full cursor-pointer rounded-xl px-1 py-2 hover:bg-lightSystemInactiveMenuItem dark:hover:bg-darkSystemHoverMenuItem"
            @click="selectItem(option)">
            {{ option.label }}
          </li>
        </ul>
      </div>
      <div
        v-if="isLoading === true"
        class="relative z-20 w-full overflow-y-auto rounded-b-xl bg-white p-2 dark:bg-darkSystemGeneral">
        Carregando...
      </div>
      <div
        v-if="value !== '' && isLoading === false && noItem === true"
        class="relative z-20 w-full overflow-y-auto rounded-b-xl bg-lightSystemGeneral p-2 dark:bg-darkSystemGeneral">
        Nenhum resultado encontrado
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import type { SelectOption } from '@/interfaces/base/select/option';
  import { defineComponent, type PropType } from 'vue';

  export default defineComponent({
    name: 'SelectWithSearch',
    props: {
      error: {
        type: String,
        default: '',
      },
      /**
       * Label do campo
       */
      label: {
        type: String,
        default: '',
      },
      /**
       * Texto para exibição
       */
      placeholder: {
        type: String,
        default: '',
      },
      defaultValue: {
        type: String || null,
        required: false,
        default: null,
      },
      /**
       * v-model
       */
      modelValue: {
        type: String,
        required: true,
        default: 'null',
      },
      /**
       * Lista de opções do select
       */
      options: {
        type: Array as PropType<Array<SelectOption>>,
        default: () => [],
      },
      isLoading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['selectItem'],
    data(props) {
      return {
        value: 'null',
        noItem: false,
        filteredItems: props.options as Array<SelectOption>,
        openItens: false,
        inputValue:
          this.$props.options.find((item) => item.value === props.modelValue)
            ?.label ?? '',
      };
    },
    computed: {
      updatedModelValue() {
        return this.modelValue;
      },
    },
    mounted() {
      const selectedValue = this.options.find(
        (item) =>
          item.value === this.modelValue || item.value === this.defaultValue,
      );

      this.value = selectedValue?.label ?? 'null';
      this.filteredItems = this.options;
    },
    methods: {
      filter() {
        if (this.inputValue === '') {
          this.filteredItems = this.options;
          this.inputValue = '';
        } else {
          this.filteredItems = this.options.find((option) =>
            option.label.toLowerCase().includes(this.inputValue.toLowerCase()),
          )
            ? this.options.filter((option) =>
                option.label
                  .toLowerCase()
                  .includes(this.inputValue.toLowerCase()),
              )
            : this.options;
        }
      },
      selectItem(item: SelectOption) {
        this.value = item.label;
        this.inputValue = item.label;
        this.openItens = false;
        this.$emit('selectItem', item);
      },
    },
  });
</script>
