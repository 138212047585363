import { defineStore } from 'pinia';
import { computed, reactive } from 'vue';
import { useUserStore } from '@/stores/user.store';
import { useSubsidiaryStore } from './subsidiary.store';

/**
 * Estrutura
 * Componente + Nome do item
 */
type Features = 'sidebar/changeSubsidiarySelect' | 'app/showPrivacyPolicy';

/**
 * Store de feature flags
 * Armazena flag de cada componente para controle de funcionalidades
 */
export const useFeatureFlagsStore = defineStore(
  'AtenaFeatureFlagStore',
  () => {
    const userStore = useUserStore();
    const subsidiaryStore = useSubsidiaryStore();

    /**
     * Armazenamento das flags binárias
     */
    const booleanFeatures = reactive<Record<Features, boolean>>({
      'app/showPrivacyPolicy': false,
      'sidebar/changeSubsidiarySelect': false,
    });

    /**
     * Define se o usuário pode ver o select de mudança de filial
     * @returns
     */
    const userCanViewSubsidiarySelect = () => {
      let userCanChangeSubsidiary = userStore.userHasPermissionTo(
        'user',
        'changeSubsidiary',
      );

      if (
        subsidiaryStore.companies.length === 1 &&
        subsidiaryStore.companies.length <= 1
      ) {
        userCanChangeSubsidiary = false;
      }

      return userCanChangeSubsidiary;
    };

    const flags = computed(() => {
      booleanFeatures['sidebar/changeSubsidiarySelect'] =
        userCanViewSubsidiarySelect();

      return booleanFeatures;
    });

    return {
      flags,
      handleFeature: (feature: Features, value: boolean) => {
        booleanFeatures[feature] = value;
      },
    };
  },
  {
    persist: {
      key: 'AtenaFeatureFlagStore',
    },
  },
);
