const parameterizedRoute = {
  preRegistration: [
    {
      name: 'vinicius-viegas',
      id: '2122288c-b112-42f8-bbf0-6362ff168403',
    },
    {
      name: 'lillian-souza',
      id: '4164b129-e53d-4254-b323-61e931014b4e',
    },
    {
      name: 'teste',
      id: '603534874',
    },
  ],
};

export default parameterizedRoute;
