import type {
  AxiosErrorInterface,
  ErrorTitlesIds,
} from '@/interfaces/base/axiosError.interface';
import type { NotificationInterface } from '@/interfaces/components/notification/notification.interface';
import axios from 'axios';

export const errorTypes: Record<ErrorTitlesIds, string> = {
  LOGIN_ERROR: 'Falha ao fazer login',
  VALIDATION_ERROR: 'Erro de validação',
  PERSON_DUPLICATED_ERROR: 'CPF duplicado',
  PAYMENT_REFUSED_ERROR: 'Erro no pagamento',
  UNAUTHENTICATED_ERROR: 'Erro de autenticação',
  INVALID_OPERATION_VALUE_ERROR: 'Valor inválido',
  ENTITY_NOT_FOUND_ERROR: 'Registro não encontrado',
  SUBSCRIPTION_NOT_FOUND_ERROR: 'Conta indisponível',
  SUBSIDIARY_IS_NOT_ACTIVE_ERROR: 'Cadastro inativo',
  DATABASE_ERROR: 'Erro ao acessar o banco de dados',
  SUBSCRIPTION_ALREADY_EXISTS_ERROR: 'Assinatura duplicada',
  INTERNAL_SERVER_ERROR: 'Falha na comunicação com o servidor',
  PAYMENT_REFUSED_ADMINISTRATOR_ERROR: 'Erro ao encontrar assinatura',
  SUBSIDIARY_ALREADY_EXISTS_WITH_DOCUMENT_ERROR: 'Cadastro duplicado',
  USER_POLICIES_NOT_ACCEPTED_ERROR: 'Política de privacidade não aceita',
  SUBSCRIPTION_NOT_FOUND_ADMINISTRATOR_ERROR: 'Erro ao encontrar assinatura',
};

/**
 * Trata o erro do axios
 * @param error Error do try catch
 * @param message Mensagem opcional caso o erro não seja resolvido
 * @returns notificação com as informações do erro
 */
export const useErrorMessage = (
  error: unknown,
  message: string,
): { notification: NotificationInterface } => {
  if (!axios.isAxiosError<AxiosErrorInterface>(error)) {
    return {
      notification: {
        message: message,
        type: 'error',
        title: 'Erro desconhecido',
        showMessage: true,
      },
    };
  }
  /**
   * Se erro de validação, retorna a mensagem de erro
   */
  if (
    error.response &&
    'type' in error.response.data &&
    'errors' in error.response.data
  ) {
    return {
      notification: {
        message: error.response.data.errors[0].message,
        type: 'error',
        title: errorTypes[error.response.data.type] ?? error.response.data.type,
        showMessage: true,
        id: error.response.data.type,
      },
    };
  }

  if (
    error.response &&
    'errors' in error.response.data &&
    'title' in error.response.data.errors[0]
  ) {
    return {
      notification: {
        message: error.response.data.errors[0].message,
        type: 'error',
        title: error.response.data.errors[0].title,
        showMessage: true,
      },
    };
  }

  if (
    error.response &&
    'message' in error.response.data &&
    'type' in error.response.data
  ) {
    return {
      notification: {
        message: error.response?.data.message ?? error.message,
        type: 'error',
        title: error.response.data.type
          ? errorTypes[error.response.data.type]
          : 'Erro',
        showMessage: true,
        id: error.response.data.type,
      },
    };
  }
  return {
    notification: {
      message: message,
      type: 'error',
      title: 'Erro desconhecido',
      showMessage: true,
    },
  };
};
