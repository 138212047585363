const image = {
  bextPro: '',
  iconWebapp: '/images/brand/iconBext.svg',
  profile: {
    user: '/images/system/user.png',
  },
  system: {
    noItemDark: '/images/system/noItems.svg',
    noItem: '/images/system/noItemsDark.svg',
    paymentProcessor: '/images/system/pagbank-logo.png',
    send: '/images/system/send.svg',
    faq: '/images/system/faq-header.svg',
  },
  brand: {
    whiteLogo: '/images/brand/bextProWhite.svg',
    grayLogo: '/images/brand/bextProGray.png',
    whiteBextLogo: '/images/brand/bextWhite.svg',
    blackBextLogo: '/images/brand/bextBlack.svg',
    iconBext: '/images/brand/iconBext.svg',
  },
  lightLogoSvg: '/images/sejabest/logo.svg',
  lightIcon: '/images/brand/iconBext.svg',
  darkLogoSvg: '/images/sejabest/logoDark.svg',
  darkIcon: '/images/sejabest/iconDark.svg',
  heroImageLoginScreen: '/images/login/heroImage.png',
  sejaBest: {
    white: '/images/sejabest/logoWhiteSejaBest.svg',
  },
  simulation: {
    best: '/images/brand/blueIcon.svg',
  },
  preRegistration: {
    banner: '/images/system/precadastro.webp',
  },
};

export default image;
