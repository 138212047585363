<template>
  <Bar :id="id" :data="chartData" :options="chartOptions" />
</template>
<script setup lang="ts">
  import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
  } from 'chart.js';
  import type { ChartData, ChartOptions } from 'chart.js';
  import { Bar } from 'vue-chartjs';
  import { ref, watch } from 'vue';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );

  const props = defineProps({
    data: {
      type: Object as () => ChartData<'bar'>,
      required: true,
    },
    options: {
      type: Object as () => ChartOptions<'bar'>,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  });

  const chartData = ref<ChartData<'bar'>>(props.data);
  const chartOptions = ref<ChartOptions<'bar'>>(props.options);

  watch(chartOptions.value, (oldValue, newValue) => {
    const chart = ChartJS.getChart(props.id);
    if (!chart) return;
    chart.options.scales = newValue.scales;
    chart.update();
  });
</script>
