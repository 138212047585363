/**
 * Transforma uma string em slug
 * @param str texto a ser transformado
 * @returns slug
 * @example createSlug('Teste de slug') // teste-de-slug
 */
export const useSlug = (str: string) => {
  let slug = str.toLowerCase();
  slug = slug.replace(/\s+/g, '-');
  slug = slug.replace(/[^a-z0-9-]+/g, '');
  return slug;
};
