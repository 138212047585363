<script setup lang="ts">
  import type { HTMLAttributes } from 'vue';
  import { Primitive, type PrimitiveProps } from 'radix-vue';
  import { type ButtonVariants, buttonVariants } from '.';
  import { cn } from '@/lib/utils';

  enum IconType {
    delete = 'fa-solid fa-trash',
    edit = 'fa-solid fa-pencil',
    add = 'fa-solid fa-plus',
    save = 'fa-solid fa-save',

    home = 'fa-solid fa-home',
    settings = 'fa-solid fa-gear',
    back = 'fa-solid fa-arrow-left',

    success = 'fa-solid fa-check',
    error = 'fa-solid fa-xmark',
    warning = 'fa-solid fa-triangle-exclamation',

    user = 'fa-solid fa-user',
    search = 'fa-solid fa-magnifying-glass',
  }

  interface Props extends PrimitiveProps {
    isLoading?: boolean;
    icon?: IconType | string;
    size?: ButtonVariants['size'];
    class?: HTMLAttributes['class'];
    variant?: ButtonVariants['variant'];
  }

  const props = withDefaults(defineProps<Props>(), {
    as: 'button',
    icon: undefined,
    isLoading: false,
  });

  const getIconClass = (icon: IconType | string) => {
    if (typeof icon === 'string' && Object.keys(IconType).includes(icon)) {
      return IconType[icon as keyof typeof IconType];
    }

    return icon;
  };
</script>

<template>
  <Primitive
    :as="as"
    :asChild="asChild"
    :class="
      cn(
        buttonVariants({ variant, size }),
        props.class,
        'flex items-center gap-2',
      )
    ">
    <div v-if="isLoading" class="">
      <slot name="loading">
        <!-- Default loading slot -->
        <IsLoading class="h-4 w-4" />
      </slot>
    </div>

    <i v-else-if="icon" :class="[getIconClass(icon), 'inline-block']"></i>

    <slot />
  </Primitive>
</template>
