import axios, { type AxiosInstance } from 'axios';
import { useUserStore } from '@/stores/user.store';
import { useErrorMessage } from './useErrorMessage';

export class UseHttp {
  private readonly instance: AxiosInstance;

  /**
   * Cria uma instância de UseHttp.
   * @param baseURL O URL base para as solicitações HTTP.
   * @param useAuthStore define se o token de autenticação deve ser adicionado aos cabeçalhos.
   */
  constructor(baseURL: string, useAuthStore: boolean = false) {
    this.instance = axios.create({
      baseURL,
      headers: { 'Accept-Language': 'pt-BR' },
    });

    if (useAuthStore) this.setupAuthenticator();

    this.setupResponseInterceptor();
  }

  /**
   * Obtém a instância do Axios.
   * @returns A instância do Axios.
   */
  public get getInstance(): AxiosInstance {
    return this.instance;
  }

  /**
   * Configura a autenticação para adicionar o token aos cabeçalhos.
   */
  private setupAuthenticator(): void {
    this.instance.interceptors.request.use(
      (config) => {
        const userStore = useUserStore();
        const token = userStore.auth?.accessToken ?? null;

        if (token) {
          config.headers.Authorization = token.includes('Bearer')
            ? token
            : `Bearer ${token}`;
        } else {
          this.setAuthToken(null);
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  /**
   * Define o token de autenticação.
   * @param token O token de autenticação.
   */
  private setAuthToken(token: string | null): void {
    if (token) {
      this.instance.defaults.headers.common['Authorization'] = token;
    } else {
      delete this.instance.defaults.headers.common['Authorization'];
    }
  }

  /**
   * Configura o interceptor de resposta para lidar com erros.
   */
  private setupResponseInterceptor(): void {
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 500) {
          return Promise.reject({
            status: 500,
            message: useErrorMessage(error, error.response),
          });
        }
        return Promise.reject(error);
      },
    );
  }
}
