<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="spinner"
      width="100%"
      height="100%"
      viewBox="0 0 50 50">
      <circle
        class="path"
        fill="none"
        stroke-width="5"
        stroke-linecap="round"
        cx="25"
        cy="25"
        r="20"></circle>
    </svg>
  </div>
</template>
