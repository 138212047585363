<script setup lang="ts">
  import { type HTMLAttributes, computed } from 'vue';
  import { ToastClose, type ToastCloseProps } from 'radix-vue';
  import { X } from 'lucide-vue-next';
  import { cn } from '@/lib/utils';

  const props = defineProps<
    ToastCloseProps & {
      class?: HTMLAttributes['class'];
    }
  >();

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
  });
</script>

<template>
  <ToastClose
    v-bind="delegatedProps"
    :class="
      cn(
        'absolute right-2 top-2 rounded-md p-1 text-zinc-950/50 opacity-0 transition-opacity hover:text-zinc-950 focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100 group-[.destructive]:text-red-300 group-[.success]:text-green-300 group-[.warning]:text-yellow-300 group-[.destructive]:hover:text-red-300 group-[.success]:hover:text-green-200 group-[.warning]:hover:text-yellow-200 group-[.destructive]:focus:ring-red-400 group-[.success]:focus:ring-green-400 group-[.warning]:focus:ring-yellow-400 group-[.destructive]:focus:ring-offset-red-600 group-[.success]:focus:ring-offset-green-600 group-[.warning]:focus:ring-offset-yellow-600 dark:text-zinc-50/50 dark:hover:text-zinc-50',
        props.class,
      )
    ">
    <X class="h-4 w-4" />
  </ToastClose>
</template>
