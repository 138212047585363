import FormData from 'form-data';
import type { LoginDTO } from '@/interfaces/user/dto/login.dto';
import type { RequestParametersInterface } from '@/interfaces/user/requestParameters.interface';
import type { UpdateUserRequestInterface } from '@/interfaces/user/updateUserRequest.interface';
import type { UserRegistrationInterface } from '@/interfaces/user/userRegistration.interface';
import { UserServices } from '@/services/user.services';
import { useNotificationStore } from '@/stores/notification.store';

export class UserModel {
  private service = new UserServices();
  private notificationStore = useNotificationStore();

  /**
   * Realiza a chamada de login ao service,
   * `Success`: Salva no localStorage
   * `Fail`: Notification
   * @param {string} login.user email do usuário
   * @param {string} login.password senha do usuário
   * @returns Redirecionamento para a página inicial
   */
  async login(login: LoginDTO) {
    const request = await this.service.login(login);

    if ('notification' in request) {
      this.notificationStore.addNotification(request.notification);
      throw new Error(request.notification.message);
    }
    return request.data;
  }

  /**
   * Realiza a chamada de login ao service
   * Atualiza os dados de autenticação no localStorage
   * @param {string} refreshToken token de atualização
   * @returns Dados do token atualizado
   */
  async refreshToken(refreshToken: string) {
    const request = await this.service.refreshToken(refreshToken);
    if ('notification' in request) {
      this.notificationStore.addNotification(request.notification);
      throw new Error(request.notification.message);
    }
    const { data } = request;
    return data;
  }

  /**
   * Realiza a chamada para aceitar as políticas de privacidade.
   * @param data Objeto contendo `acceptTermsOfUse` e `acceptPrivacyPolicy`
   * @returns Atualiza a página em caso de sucesso
   */
  async acceptPolicies(data: {
    acceptTermsOfUse: boolean;
    acceptPrivacyPolicy: boolean;
  }) {
    const request = await this.service.acceptPolicies(data);
    if ('notification' in request) {
      this.notificationStore.addNotification(request.notification);
      throw new Error(request.notification.message);
    }
    return request.data;
  }

  /**
   * Envia o email passado por parâmetro para que seja a senha da conta seja recuperada.
   * @param {string} email
   * @returns Objeto contendo mensagem de sucesso
   */
  async recoverPassword(email: string) {
    const request = await this.service.recoverPassword(email);
    if ('notification' in request) {
      this.notificationStore.addNotification(request.notification);
      throw new Error(request.notification.message);
    }
    this.notificationStore.addNotification({
      message:
        'Você receberá um e-mail para prosseguir com a redefinição de senha.',
      showMessage: true,
      title: 'Solicitação enviado com sucesso!',
      type: 'success',
    });
    return request.data;
  }

  /**
   * Realiza a chamada à API para redefinir a senha de usuário
   * @param {sting} token token recebido por email
   * @param {string} password senha digitada no input
   * @param {string} passwordConfirmation confirmação de senha do input
   * @returns
   */
  async newPassword(
    token: string,
    password: string,
    passwordConfirmation: string,
  ) {
    const request = await this.service.newPassword(
      token,
      password,
      passwordConfirmation,
    );
    if ('notification' in request) {
      this.notificationStore.addNotification(request.notification);
      throw new Error(request.notification.message);
    }
    this.notificationStore.addNotification({
      type: 'success',
      title: 'sucesso',
      message: 'Senha alterada!',
      showMessage: true,
    });
    return request.data;
  }

  /**
   * Realiza a chamada à API para obter a lista de usuários cadastrados no sistema
   * @param params Filtro
   * @returns Lista de usuários paginada
   */
  async getUsersWithParameters(params?: Partial<RequestParametersInterface>) {
    const request = await this.service.getUsersWithParameters(params);
    if ('notification' in request) {
      this.notificationStore.addNotification(request.notification);
      throw new Error(request.notification.message);
    }
    return request.data;
  }

  /**
   * Filtro de busca que irá procurar pelo:
   *
   * - Nome da pessoa associada ao usuário.
   *
   * - Documento da pessoa associada ao usuário.
   *
   * - Contatos da pessoa associada ao usuário.
   * @param search
   */
  async getUsersWithAutoComplete(search?: string) {
    const request = await this.service.getUsersWithAutoComplete(search);
    if ('notification' in request) {
      this.notificationStore.addNotification(request.notification);
      throw new Error(request.notification.message);
    }
    return request.data;
  }

  /**
   * Remove um usuário do sistema
   * @param userId
   * @returns 204
   */
  async removeUser(userId: string) {
    const request = await this.service.removeUser(userId);
    if ('notification' in request) {
      this.notificationStore.addNotification(request.notification);
      throw new Error(request.notification.message);
    }
    return request.data;
  }

  /**
   * Adiciona um usuário ao sistema
   * @param user Dados do usuário
   * @returns Objeto com informações do usuário
   */
  async createUser(user: UserRegistrationInterface) {
    const request = await this.service.createUser(user);
    if ('notification' in request) {
      this.notificationStore.addNotification(request.notification);
      throw new Error(request.notification.message);
    }
    return request.data;
  }

  /**
   * Atualiza as informações de perfil do usuário
   * @param payload
   * @returns 204
   */
  async updateUser(payload: UpdateUserRequestInterface) {
    const request = await this.service.updateUser(payload);
    if ('notification' in request) {
      this.notificationStore.addNotification(request.notification);
      throw new Error(request.notification.message);
    }
    return request.data;
  }

  async updateProfilePicture(img: FormData) {
    const request = await this.service.updateProfilePicture(img);
    if ('notification' in request) {
      this.notificationStore.addNotification(request.notification);
      throw new Error(request.notification.message);
    }
    return request.data;
  }

  /**
   * Altera a empresa matriz atual do usuário
   * @param {string} companyId ID da empresa matriz
   * @returns status code 200
   */
  async changeCompany(companyId: string) {
    const request = await this.service.changeCompany({
      company: {
        id: companyId,
      },
    });
    if ('notification' in request) {
      this.notificationStore.addNotification(request.notification);
      throw new Error(request.notification.message);
    }
    return request.data;
  }

  /**
   * Altera a empresa filial atual do usuário
   * @param {string} subsidiaryId ID da empresa filial
   * @returns status code 200
   */
  async changeSubsidiary(subsidiaryId: string) {
    const request = await this.service.changeSubsidiary(subsidiaryId);

    if ('notification' in request) {
      this.notificationStore.addNotification(request.notification);
      throw new Error(request.notification.message);
    }
    // const userInformation = this.userStore.profile;
    // if (userInformation) {
    //   userInformation.subsidiary.id = subsidiaryId;
    //   // this.userStore.setUserInformation(userInformation);
    // }

    return request.data;
  }
}
