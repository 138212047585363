import type { Pipeline } from '@/interfaces/pipeline/pipeline.interface';
import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { MenuItem } from '@/interfaces/components/menu/itemsMenu.interface';
import { useSlug } from '@/composables/useSlug';

export const usePipelineStore = defineStore(
  'AtenaPipelineStore',
  () => {
    const pipelines = ref<Pipeline[] | null>(null);

    const clearPipelines = () => {
      pipelines.value = null;
    };

    const setPipelines = async (payload: Pipeline[]) => {
      pipelines.value = payload;
    };

    /**
     * Armazena as urls das rotas no formato { nome-da-pipeline: uuid-v4 }
     * @example { pipeline-cgv: '63257880-210f-463c-9804-51651d39c658' }
     */
    const urls: Record<string, string> = {};

    /**
     * Método para criar as opções de menu para o menu lateral
     * @returns
     */
    const pipelineMenuOptions = (): Array<MenuItem> => {
      if (!pipelines.value) return [];
      return pipelines.value.map((pipeline) => {
        urls[useSlug(pipeline.name)] = pipeline.id;
        return {
          name: pipeline.name,
          icon: 'fa-solid fa-grip',
          isDefaultIcon: true,
          route: `/meus-negocios/${useSlug(pipeline.name)}`,
          subItems: [],
          isVisible: true,
        };
      });
    };

    return {
      urls,
      pipelines,
      setPipelines,
      clearPipelines,
      pipelineMenuOptions,
    };
  },
  {
    persist: true,
  },
);
