<template>
  <div>
    <h1 class="regularActiveText text-4xl font-bold sm:text-2xl">
      {{ pageTitle }}
    </h1>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue';
  export default defineComponent({
    name: 'PageTitle',
    props: {
      pageTitle: {
        type: String,
        required: true,
      },
    },
  });
</script>
